<template>
    <b-card>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form @submit.prevent="handleSubmit(onSubmit)">

                <b-row>

                    <b-col cols="12" md="12" lg="12">
                        <div class="d-flex">
                            <feather-icon icon="FeatherIcon" size="16"/>
                            <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
                        </div>

                        <b-row class="mt-1 mb-1">

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="İsim" rules="required">
                                    <b-form-group label="* İsim">
                                        <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Ülke" rules="required">
                                    <b-form-group label="* Ülke" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Resmi Tatil Tipi" rules="required">
                                    <b-form-group label="* Resmi Tatil Tipi" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.officialDayTypeId" :options="officialDayTypeOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Durum" rules="required">
                                    <b-form-group label="* Durum" label-for="isActive" :state="getValidationState(validationContext)">
                                        <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>

                        <div class="d-flex">
                            <feather-icon icon="CalendarIcon" size="16"/>
                            <h5 class="mb-0 ml-50">Tarih Bilgileri</h5>
                        </div>

                        <b-row class="mt-1">

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Yıl" rules="required">
                                    <b-form-group label="* Yıl">
                                        <b-form-input trim placeholder="Yıl" v-model="dataInfo.year" :state="getValidationState(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Başlangıç Tarihi" rules="required">
                                    <b-form-group label="* Başlangıç Tarihi" :state="getValidationState(validationContext)">
                                        <flatpickr v-model="dataInfo.startDate" class="form-control" :config="{enableTime: true, defaultHour: 0, defaultMinute: 0, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" md="6" lg="6">
                                <validation-provider #default="validationContext" name="Bitiş Tarihi" rules="required">
                                    <b-form-group label="* Bitiş Tarihi" :state="getValidationState(validationContext)">
                                        <flatpickr v-model="dataInfo.endDate" class="form-control" :config="{enableTime: true, defaultHour: 23, defaultMinute: 59, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>

                    </b-col>

                </b-row>

                <action-buttons :back-route="'definition-officialday-list'"/>
            </b-form>
        </validation-observer>
        <Overlay :busy="busy"></Overlay>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/officialday/store"
import definitionModule from "@/views/system/definition/store"
import addressModule from "@/store/common/address"
import flatpickr from "vue-flatpickr-component/src/component"

export default {
    components: {
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BTabs,
        BTab,
        BFormTextarea,
        BAvatar,

        flatpickr,

        ActionButtons,
        Overlay,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    props: {},
    data() {
        return {
            required,
            email,
        }
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        const STORE_DEFINITION_MODULE_NAME = 'store-definition'
        const STORE_ADDRESS_MODULE_NAME = 'store-address'

        if (!store.hasModule(STORE_MODULE_NAME)) {
            store.registerModule(STORE_MODULE_NAME, storeModule)
            store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
            store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
        }
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) {
                store.unregisterModule(STORE_MODULE_NAME)
                store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
                store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
            }
        })

        const toast = useToast()
        const busy = ref(false)
        const dataInfo = ref({
            id: 0,
            name: '',
            countryId: null,
            officialDayTypeId: null,
            year: null,
            startDate: null,
            endDate: null,
            isSystem: false,
            isActive: true
        })
        const onSubmit = () => {
            busy.value = true
            store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
                toastMessage(toast, 'success', response.data.message)
                router.push({name: 'definition-officialday-list'})
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
            }).finally(message => {
                busy.value = false
            })
        }

        const countryOptions = ref([])
        busy.value = true
        store.dispatch('store-address/countryList').then(response => {
            response.data.data.forEach((value, index) => {
                countryOptions.value.push({label: value.name, value: value.id})
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        const officialDayTypeOptions = ref([])
        busy.value = true
        store.dispatch('store-definition/definitionList', {groupKey: 'OFFICIAL_DAY_TYPE'}).then(response => {
            response.data.data.forEach((value, index) => {
                officialDayTypeOptions.value.push({label: value.name, value: value.key})
                if (value.isDefault && router.currentRoute.params.id === undefined) {
                    dataInfo.value.officialDayTypeId = value.key
                }
            });
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        if (router.currentRoute.params.id > 0) {
            busy.value = true
            store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
                dataInfo.value = response.data.data
            }).catch(error => {
                toastMessage(toast, 'danger', error.response.data.message)
                if (error.response.status === 403) {
                    router.push({name: 'definition-officialday-list'})
                }
            }).finally(message => {
                busy.value = false
            })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation()

        return {
            busy,
            dataInfo,
            refFormObserver,
            countryOptions,
            officialDayTypeOptions,

            statusOptions,
            yesNoOptions,

            onSubmit,
            getValidationState,
            resetForm,

            getApiFile,
            avatarText,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>